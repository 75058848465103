import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  { path: 'rezervasyon', loadChildren: () => import('./components/pages/rezervasyon/rezervasyon.module').then(m => m.RezervasyonModule), data: { breadcrumb: "Rezervasyon" } },
  { path: 'arac-secimi', loadChildren: () => import('./components/pages/arac-secim/arac-secim.module').then(m => m.AracSecimModule), data: { breadcrumb: "Araç Seçimi" } },
  { path: 'extra-secenek', loadChildren: () => import('./components/pages/extra-secenek/extra-secenek.module').then(m => m.ExtraSecenekModule), data: { breadcrumb: "Extra Seçenek" } },
  { path: 'tum-arac', loadChildren: () => import('./components/pages/vehicles/vehicles.module').then(m => m.VehiclesModule), data: { breadcrumb: "Tüm Araçlar" } },
  { path: 'iletisim', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "İletişim" } },
  { path: 'office', loadChildren: () => import('./components/pages/our-office/our-office.module').then(m => m.OurOfficeModule), data: { breadcrumb: "Ofislerimiz" } },
  { path: 'hakkimizda', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "Hakkımızda" } },
  { path: 'kampanyalar', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Kampanyalar" } },
  { path: 'kvkk', loadChildren: () => import('./components/pages/bildiri/bildiri.module').then(m => m.BildiriModule), data: { breadcrumb: "KVKK Metni" } },
  { path: 'aydinlatma-metin', loadChildren: () => import('./components/pages/aydinlatma-metin/aydinlatma-metin.module').then(m => m.AydinlatmaMetinModule), data: { breadcrumb: "Aydınlatma Metni" } },
  { path: 'cerez', loadChildren: () => import('./components/pages/cerez/cerez.module').then(m => m.CerezModule), data: { breadcrumb: "Çerez Aydınlatma Metni" } },
  { path: 'order-detail', loadChildren: () => import('./components/pages/order-detail/order-detail.module').then(m => m.OrderDetailModule), data: { breadcrumb: "Rezervasyon Bilgileri" } },
  


  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: "Anasayfa" } },
  { path: 'rezervasyon', loadChildren: () => import('./components/pages/rezervasyon/rezervasyon.module').then(m => m.RezervasyonModule), data: { breadcrumb: "Rezervasyon" } },
  { path: 'arac-secimi', loadChildren: () => import('./components/pages/arac-secim/arac-secim.module').then(m => m.AracSecimModule), data: { breadcrumb: "Araç Seçimi" } },
  // { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: "Hakkımızda" } },
  { path: 'blog/cat/:catId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/tag/:tagId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog/user/:authorId', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-details/:id', loadChildren: () => import('./components/pages/blog-details/blog-details.module').then(m => m.BlogDetailsModule), data: { breadcrumb: "Nasıl Araç Kiralarım" } },
  { path: 'blog-details-left/:id', loadChildren: () => import('./components/pages/blog-details-left/blog-details-left.module').then(m => m.BlogDetailsLeftModule), data: { breadcrumb: "Blog Details" } },
  { path: 'blog-details-right/:id', loadChildren: () => import('./components/pages/blog-details-right/blog-details-right.module').then(m => m.BlogDetailsRightModule), data: { breadcrumb: "Blog Details" } },
  { path: 'blog-left', loadChildren: () => import('./components/pages/blog-left/blog-left.module').then(m => m.BlogLeftModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'blog-right', loadChildren: () => import('./components/pages/blog-right/blog-right.module').then(m => m.BlogRightModule), data: { breadcrumb: "Blog Grid" } },
  { path: 'booking', loadChildren: () => import('./components/pages/booking/booking.module').then(m => m.BookingModule), data: { breadcrumb: "Booking" } },
  { path: 'arac-kirala/:id', loadChildren: () => import('./components/pages/car-details/car-details.module').then(m => m.CarDetailsModule), data: { breadcrumb: "Araç Kirala" } },
  { path: 'cars', loadChildren: () => import('./components/pages/cars/cars.module').then(m => m.CarsModule), data: { breadcrumb: "Araçlar" } },
  { path: 'cars-left', loadChildren: () => import('./components/pages/cars-left/cars-left.module').then(m => m.CarsLeftModule), data: { breadcrumb: "Cars Grid" } },
  { path: 'cars-right', loadChildren: () => import('./components/pages/cars-right/cars-right.module').then(m => m.CarsRightModule), data: { breadcrumb: "Cars Grid" } },
  { path: 'coming-soon', loadChildren: () => import('./components/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule), data: { breadcrumb: "Coming Soon" } },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: "İletişim" } },
  { path: 'faqs', loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule), data: { breadcrumb: "FAQ's" } },
  { path: 'gallery', loadChildren: () => import('./components/pages/gallery/gallery.module').then(m => m.GalleryModule), data: { breadcrumb: "Gallery" } },
  { path: 'error', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: "Error 404" } },
  { path: '**', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: "Error 404" } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
